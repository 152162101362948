import { graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import { strToTitleCase } from "../functions";
import styled, { css } from "styled-components";
import { useRenderChart } from "../components/charts/chart";
import SEO from "../components/seo";
import { device } from "../components/breakpoint";
const Container = styled.div`
  padding: 0px 160px;
  max-width: 900px;
  margin: 0 auto;
  h1 {
    margin-top: 80px;
    text-align: center;
    font-size: 40px;
  }
  ${device.sm`
    margin-top: 55px;
    padding: 0px 10px;
  `}
  ${css`
    [data-test-id="chart-description"] {
      white-space: wrap;
    }
  `}
`;

const Grid = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 475px));
  grid-auto-rows: 600px;
  justify-content: center;
`;

const TextGrid = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(375px, 475px));
  grid-auto-rows: min-content;
  justify-content: center;
  grid-row-gap: 24px;
  padding: 36px 0px;
  .item {
    border: solid 1px var(--primary);
  }
  .heading {
    font-size: 20px;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    color: var(--c2);
  }
  .info {
    padding: 20px;
  }
`;

const MajorKeysContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .items {
    padding: 12px;
    display: grid;
    grid-row-gap: 8px;
    .title {
      font-size: 24px;
      text-align: center;
    }
    .item {
      font-size: 16px;
    }
  }
`;

const City = ({ data, pageContext }) => {
  const { shortTermRentalSetAverages } = data;
  const { name, shortTermRentalGroup } = shortTermRentalSetAverages;
  const { charts } = shortTermRentalGroup;
  const {
    person_capacity,
    rating_cleanliness,
    price_rate,
    photos,
    beds,
    bathrooms,
    rating_location,
  } = shortTermRentalGroup;
  // console.log(data, shortTermRentalGroup);
  const cityTitle = strToTitleCase(name);
  useEffect(() => {
    let elements = [
      ...document.querySelectorAll('[data-test-id="chart-description"]'),
    ];
    if (elements.length) {
      elements.map((elem) => {
        elem.style = "white-space:none;";
      });
    }
  }, []);
  return (
    <div>
      <SEO
        title={`Short Term Rental AirBnB Market Data For ${strToTitleCase(
          name
        )}`}
        description={`${cityTitle} AirBnB Short Term Rentals $${(
          price_rate * 30
        ).toFixed(
          2
        )} a Month in Revenue. Use our powerful data for a profitable Airbnb strategy. Our short term rental software gives you the tools and insights to be the most profitable Airbnb in the rental markets.`}
      />
      <Container>
        <h1>
          {strToTitleCase(name)} Short Term Rental Airbnb Market Data: $
          {(price_rate * 30).toFixed(2)} Per Month in Revenue.
        </h1>
        <div className="block">
          <span>Be sure to checkout the Airbnb Guide and Strategies for </span>
          <a
            style={{ textDecoration: "underline", display: "inline-block" }}
            className="text-underline"
            href={`/airbnb-guide/${name.split(" ").join("-")}`}
            target="_blank"
          >
            {cityTitle}{" "}
          </a>
          <span className="inline-block">. Here is</span>
          <a
            style={{ textDecoration: "underline" }}
            target="_blank"
            href={`/airbnb-statistics/${name.split(" ").join("-")}`}
          >
            {" "}
            &nbsp;Airbnb data and statistics
          </a>{" "}
          &nbsp;for {cityTitle}.
        </div>
      </Container>

      <Grid>
        {charts.map((chart, i) => {
          if (i > 5) return <></>;
          return <ChartItem key={i} city={name} chart={chart} />;
        })}
      </Grid>

      <TextGrid className="text">
        <h2
          className="heading"
          style={{ gridColumn: "1/-1", textAlign: "center" }}
        >
          On this page you will find all the following data & insights about the
          short term rental market in {cityTitle}. RentalGuideAI is your AirBnB
          guide and will teach you how to do AirBnB profitably with evidence
          backed by real data.
        </h2>

        <h2 className="heading" style={{ gridColumn: "1/-1" }}>
          {cityTitle} Airbnb Market data averages
        </h2>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Beds / Bedrooms
          </h2>
          <p className="info">
            <span>
              The average number of Beds in {cityTitle} for AirBnB is{" "}
              {beds.toFixed(2)}
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Baths / Bathrooms
          </h2>
          <p className="info">
            <span>
              {" "}
              The average number of Bathrooms in {cityTitle} for AirBnB is{" "}
              {bathrooms.toFixed(2)}
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Person Capacity
          </h2>
          <p className="info">
            <span>
              The average Person Capacity in {cityTitle} for AirBnB is{" "}
              {person_capacity.toFixed(2)}
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Cleanliness rating
          </h2>
          <p className="info">
            <span>
              The average Cleanliness Rating in {cityTitle} for AirBnB is{" "}
              {rating_cleanliness.toFixed(2)}
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Rate and how much hosts
            charge
          </h2>
          <p className="info">
            <span>
              The average Price Rate or Amount Charged in {cityTitle} for AirBnB
              is ${price_rate.toFixed(2)}
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Photos Per Listing
          </h2>
          <p className="info">
            <span>
              {" "}
              To be competitive in Airbnb you should have the average number of
              photos in {cityTitle} which is {photos.toFixed(0)}
            </span>
          </p>
        </div>

        <h2 className="heading" style={{ gridColumn: "1/-1" }}>
          How to read {cityTitle} charts to have a profitable Airbnb business.
        </h2>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Most Common and Profitable Amenities
            Word Cloud
          </h2>
          <p className="info">
            <span>
              What are the most common and profitable amenities by person
              capacity in {cityTitle}
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rental Price Geospatial Scatter Plot For The
            Most Profitable Short Term Rentals
          </h2>
          <p className="info">
            <span>
              A breakdown of the {cityTitle} Short Term Rental Market Prices
              Geospatial
            </span>
          </p>
        </div>
        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rental Price Correlated to Reviews
          </h2>
          <p className="info">
            <span>
              How does the price of your listing correlate to the number of
              reviews you get in {cityTitle}
            </span>
            <br />
          </p>
        </div>
        <div className="item">
          <h2 className="heading">
            Scatter Map of {cityTitle} for the top rated Short Term Rental
            Investments
          </h2>
          <p className="info">
            <span>
              The goal of this scatter map is to find out the specific parts of{" "}
              {cityTitle} that get the highest ratings in the Short Term Rental
              Market.
            </span>
            <br />
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            How does average number of beds and bathrooms correlate to the star
            rating of your short term rental for Airbnb in {cityTitle}
          </h2>
          <p className="info">
            <span>
              You can use this chart to find the optimal ratio of Bedroom to
              Bathrooms in the {cityTitle} Airbnb short term rental market
            </span>
            <br />
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            How does average price rate correlate to the star rating of your
            short term rental in {cityTitle} for Airbnb
          </h2>
          <p className="info">
            <span>
              You can use this chart to find the sweet spot of pricing in the
              AirBnB {cityTitle} short term rental market
            </span>
            <br />
          </p>
        </div>

        <h2 className="heading" style={{ gridColumn: "1/-1" }}>
          {cityTitle} Short Term Rental Revenue
        </h2>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Revenue Assuming 10 bookings
            per month
          </h2>
          <p className="info">
            <span>
              Doing Airbnb in {cityTitle} assuming 10 bookings a month you
              should expect to make ${(price_rate * 10).toFixed(2)} renting your
              property on Airbnb
            </span>
          </p>
        </div>
        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Revenue Assuming 20 bookings
            per month
          </h2>
          <p className="info">
            <span>
              Doing Airbnb in {cityTitle} assuming 20 bookings a month you
              should expect to make ${(price_rate * 20).toFixed(2)} renting your
              property on Airbnb
            </span>
          </p>
        </div>
        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Revenue Assuming 30 bookings
            per month
          </h2>
          <p className="info">
            <span>
              Doing Airbnb in {cityTitle} assuming 30 bookings a month you
              should expect to make ${(price_rate * 30).toFixed(2)} renting your
              property on Airbnb
            </span>
          </p>
        </div>
        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Revenue Assuming 50 bookings
            per month
          </h2>
          <p className="info">
            <span>
              Doing Airbnb in {cityTitle} assuming 50 bookings a month you
              should expect to make ${(price_rate * 50).toFixed(2)} renting your
              property on Airbnb
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Revenue Assuming 100 bookings
            per month
          </h2>
          <p className="info">
            <span>
              Doing Airbnb in {cityTitle} assuming 100 bookings a month you
              should expect to make ${(price_rate * 100).toFixed(2)} renting
              your property on Airbnb
            </span>
          </p>
        </div>

        <div className="item">
          <h2 className="heading">
            {cityTitle} Short Term Rentals Average Revenue Assuming 200 bookings
            per month
          </h2>
          <p className="info">
            <span>
              Doing Airbnb in {cityTitle} assuming 200 bookings a month you
              should expect to make ${(price_rate * 200).toFixed(2)} renting
              your property on Airbnb
            </span>
          </p>
        </div>
      </TextGrid>
    </div>
  );
};

const ChartWrapper = styled.div`
  max-width: 475px;
  width: 100%;
`;

const ChartItem = ({ chart, city }) => {
  const ref = useRef();
  useRenderChart(chart, ref, city);
  return <div ref={ref} />;
};

export default City;

export const pageQuery = graphql`
  query ($cityName: String!) {
    shortTermRentalSetAverages(name: { eq: $cityName }) {
      shortTermRentalGroup {
        beds
        bathrooms
        access
        additional_house_rules
        amenities
        weekly_price_factor
        url
        transit
        total_price
        star_rating
        satisfaction_guest
        room_type
        room_and_property_type
        review_score
        review_count
        response_time
        response_rate
        rating_value
        rating_location
        rating_communication
        rating_cleanliness
        rating_checkin
        rating_accuracy
        price_rate_type
        price_rate
        photos
        person_capacity
        notes
        neighborhood_overview
        name
        monthly_price_factor
        longitude
        interaction
        latitude
        description
        city
        charts
      }
      name
    }
  }
`;
